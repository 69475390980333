<template>
  <!--begin::Toolbar-->
  <div class="toolbar" id="kt_toolbar">
    <!--begin::Container-->
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
      <!--begin::Page title-->
      <div
        class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1"
      >
        <!--begin::Title-->
        <!--<h1
          class="d-flex align-items-center text-dark fw-bolder my-1 fs-3"
        >
          {{ title }}
        </h1>-->
        <!--end::Title-->

        <!--<span
          v-if="breadcrumbs"
          class="h-20px border-gray-200 border-start mx-4"
        ></span>-->

        <!--begin::Breadcrumb-->
        <ul
          v-if="breadcrumbs"
          class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
        >
          <li class="breadcrumb-item pe-3">
            <router-link to="/dashboard" class="text-muted text-hover-primary">
              MyAssets
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-200 w-5px h-2px"></span>
          </li>
          <template v-for="(item, index) in breadcrumbs" :key="index">
            <li class="breadcrumb-item text-muted">
              {{ item }}
            </li>
            <li class="breadcrumb-item">
              <span class="bullet bg-gray-200 w-5px h-2px"></span>
            </li>
          </template>
          <li class="breadcrumb-item pe-3 text-dark">
            {{ title }}
          </li>
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page title-->

      <!--begin::Actions-->
      <div class="d-flex align-items-center py-1">
        <!--begin::Wrapper-->
        <div class="me-4">
          <!--begin::Filter-->
          <div
            :class="{
              'd-none': type !== 'assetsList',
              'd-block': type === 'assetsList'
            }"
          >
            <AssetsFilterDropdown></AssetsFilterDropdown>
          </div>
          <div
            :class="{
              'd-none': type !== 'projectsList',
              'd-block': type === 'projectsList'
            }"
          >
            <ProjectsFilterDropdown></ProjectsFilterDropdown>
          </div>
          <div
            :class="{
              'd-none': type !== 'workOrdersList',
              'd-block': type === 'workOrdersList'
            }"
          >
            <WorkOrdersFilterDropdown></WorkOrdersFilterDropdown>
          </div>
          <div
            :class="{
              'd-none': type !== 'maintenanceBudgets',
              'd-block': type === 'maintenanceBudgets'
            }"
          >
            <MaintenanceBudgetsFilterDropdown></MaintenanceBudgetsFilterDropdown>
          </div>
          <div
            :class="{
              'd-none': type !== 'workForceSchedule',
              'd-block': type === 'workForceSchedule'
            }"
          >
            <WorkforceFilterDropdown></WorkforceFilterDropdown>
          </div>
          <div
            :class="{
              'd-none': type !== 'assetsSchedule',
              'd-block': type === 'assetsSchedule'
            }"
          >
            <AssetScheduleFilterDropdown></AssetScheduleFilterDropdown>
          </div>

          <!--end::Filter-->
        </div>
        <!--end::Wrapper-->

        <!--begin::Button-->
        <a
          href="#"
          class="btn btn-sm btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_create_work_orderxx"
          id="kt_toolbar_primary_button"
          v-if="type === 'xxxas'"
        >
          Create
        </a>

        <a
          class="btn btn-sm btn-primary"
          v-if="type === 'workOrdersCreate'"
          @click="saveWorkOrder"
        >
          Save
        </a>

        <router-link
          to="/work-orders/create"
          class="btn btn-sm btn-primary"
          v-if="type === 'workOrdersList'"
        >
          Create
        </router-link>

        <a
          href="#"
          class="btn btn-sm btn-primary"
          v-if="['assetsList', 'ddb-checklists'].includes(type)"
        >
          Create
        </a>

        <a
          v-if="type && type === 'ddb-checklist-view'"
          href="#"
          class="btn btn-sm btn-primary"
          id="reviseDdbChecklist"
        >
          Revise
        </a>

        <!--end::Button-->
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Toolbar-->
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from "vue";
import ProjectsFilterDropdown from "@/components/dropdown/ProjectsFilterDropdown.vue";
import WorkOrdersFilterDropdown from "@/components/dropdown/WorkOrdersFilterDropdown.vue";
import AssetsFilterDropdown from "@/components/dropdown/AssetsFilterDropdown.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { util } from "prismjs";
import type = util.type;
import { emitter } from "@/main";
import MaintenanceBudgetsFilterDropdown from "@/components/dropdown/MaintenanceBudgetsFilterDropdown.vue";
import WorkforceFilterDropdown from "@/components/dropdown/WorkforceFilterDropdown.vue";
import AssetScheduleFilterDropdown from "@/components/dropdown/AssetScheduleFilterDropdown.vue";

//let typesWithFilter = Array<string>();
export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
    type: String
  },
  methods: {
    saveWorkOrder: function() {
      console.log("swo click");
      emitter.emit("saveWorkOrder");
    }
  },
  components: {
    ProjectsFilterDropdown,
    AssetsFilterDropdown,
    WorkOrdersFilterDropdown,
    MaintenanceBudgetsFilterDropdown,
    WorkforceFilterDropdown,
    AssetScheduleFilterDropdown
  }
});
</script>
