
import { defineComponent, onBeforeMount } from "vue";
import ProjectsFilterDropdown from "@/components/dropdown/ProjectsFilterDropdown.vue";
import WorkOrdersFilterDropdown from "@/components/dropdown/WorkOrdersFilterDropdown.vue";
import AssetsFilterDropdown from "@/components/dropdown/AssetsFilterDropdown.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { util } from "prismjs";
import type = util.type;
import { emitter } from "@/main";
import MaintenanceBudgetsFilterDropdown from "@/components/dropdown/MaintenanceBudgetsFilterDropdown.vue";
import WorkforceFilterDropdown from "@/components/dropdown/WorkforceFilterDropdown.vue";
import AssetScheduleFilterDropdown from "@/components/dropdown/AssetScheduleFilterDropdown.vue";

//let typesWithFilter = Array<string>();
export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
    type: String
  },
  methods: {
    saveWorkOrder: function() {
      console.log("swo click");
      emitter.emit("saveWorkOrder");
    }
  },
  components: {
    ProjectsFilterDropdown,
    AssetsFilterDropdown,
    WorkOrdersFilterDropdown,
    MaintenanceBudgetsFilterDropdown,
    WorkforceFilterDropdown,
    AssetScheduleFilterDropdown
  }
});
