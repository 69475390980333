<template>
  <!--begin::Work orders filter dropdown-->
  <a
      href="#"
      class="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
  >
            <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
              <inline-svg src="media/icons/duotone/Text/Filter.svg" />
            </span>
    Filter
  </a>
  <div
    class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
    data-kt-menu="true"
  >
    <!--begin::Header-->
    <div class="px-7 py-5">
      <div class="fs-5 text-dark fw-bolder">Filter Options</div>
    </div>
    <!--end::Header-->

    <!--begin::Menu separator-->
    <div class="separator border-gray-200"></div>
    <!--end::Menu separator-->

    <!--begin::Form-->
    <div class="px-7 py-5">
      <!--begin::Input group-->
      <div class="mb-10">
        <!--begin::Label-->
        <label class="form-label fw-bold">Type:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
              class="form-select-solid"
              placeholder="Select option"
              v-model="data.type"
              @change="updateWorkOrdersFilterType"
          >
            <el-option label="All" value="All">All</el-option>
            <el-option label="General Work Order" value="General Work Order">General Work Order</el-option>
            <el-option label="Preventive Maintenance" value="Preventive Maintenance">Preventive Maintenance</el-option>
            <el-option label="Inspection" value="Inspection">Inspection</el-option>
            <el-option label="Emergency" value="Emergency">Emergency</el-option>
            <el-option label="Corrective Maintenance" value="Corrective Maintenance">Corrective Maintenance</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="mb-10">
        <!--begin::Label-->
        <label class="form-label fw-bold">Status:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
            class="form-select-solid"
            placeholder="Select option"
            v-model="data.status"
            @change="updateWorkOrdersFilterStatus"
          >
            <el-option label="All" value="All">All</el-option>
            <el-option label="WAPPR" value="WAPPR">WAPPR</el-option>
            <el-option label="APPR" value="APPR">APPR</el-option>
            <el-option label="WSCH" value="WSCH">WSCH</el-option>
            <el-option label="WMATL" value="WMATL">WMATL</el-option>
            <el-option label="WPCOND" value="WPCOND">WPCOND</el-option>
            <el-option label="INPRG" value="INPRG">INPRG</el-option>
            <el-option label="COMP" value="COMP">COMP</el-option>
            <el-option label="CLOSE" value="CLOSE">CLOSE</el-option>
            <el-option label="CAN" value="CAN">CAN</el-option>
            <el-option label="HISTEDIT" value="HISTEDIT">HISTEDIT</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->
      <!--begin::Actions-->
      <div class="d-flex justify-content-end">
        <button
          type="reset"
          @click="resetFilters"
          class="btn btn-sm btn-white btn-active-light-primary me-2"
          data-kt-menu-dismiss="true"
        >
          Reset
        </button>

        <button
          type="submit"
          class="btn btn-sm btn-primary"
          data-kt-menu-dismiss="true"
        >
          Apply
        </button>
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Form-->
  </div>
  <!--end::Work orders filter dropdown-->
</template>

<script lang="ts">
import {inject, defineComponent, ref, computed} from "vue";
import { useStore } from "vuex";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {emitter} from "@/main";

interface Filter {
  status: string;
  type: string;
}

export default defineComponent({
  name: "work-orders-filter-dropdown",
  components: {},
  methods: {
    resetFilters: function() {
      console.log("reset");
      this.data.status = "All";
      this.data.type = "All";
      this.filterWorkOrdersList();
    },
    updateWorkOrdersFilterStatus: function (selected) {
      this.data.status = selected;
      this.filterWorkOrdersList();
    },
    updateWorkOrdersFilterType: function (selected) {
      this.data.type = selected;
      this.filterWorkOrdersList();
    },
    filterWorkOrdersList: function() {
      //console.log("update triggered", this.data);
      emitter.emit('filterWorkOrdersList', this.data);
    }
  },
  setup() {
    const data = ref<Filter>({
      status: "All",
      type: "All"
    });

    return {
      data
    };
  }
});
</script>
