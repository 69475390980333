
import { defineComponent } from "vue";

export default defineComponent({
  name: "notifications-menu",
  components: {},
  setup() {
    const data1 = [
      {
        title: "Project Alice",
        description: "Phase 1 development",
        time: "1 hr",
        icon: "media/icons/duotone/Clothes/Crown.svg",
        state: "primary"
      },
      {
        title: "HR Confidential",
        description: "Confidential staff documents",
        time: "2 hrs",
        icon: "media/icons/duotone/Code/Warning-2.svg",
        state: "danger"
      },
      {
        title: "Company HR",
        description: "Corporeate staff profiles",
        time: "5 hrs",
        icon: "media/icons/duotone/Communication/Group.svg",
        state: "warning"
      },
      {
        title: "Project Baker",
        description: "New contract",
        time: "2 days",
        icon: "media/icons/duotone/General/Thunder.svg",
        state: "success"
      },
      {
        title: "Project Breafing",
        description: "Product launch status update",
        time: "21 Jan",
        icon: "media/icons/duotone/Communication/Flag.svg",
        state: "primary"
      },
      {
        title: "Spektr Group website",
        description: "Discuss animations",
        time: "21 Jan",
        icon: "media/icons/duotone/Design/Image.svg",
        state: "info"
      }
    ];

    const data2 = [
      {
        code: "200 OK",
        state: "success",
        message: "New project",
        time: "Just now"
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "New customer",
        time: "2 hrs"
      },
      {
        code: "200 OK",
        state: "success",
        message: "Payment process",
        time: "5 hrs"
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "Search query",
        time: "2 days"
      },
      {
        code: "200 OK",
        state: "success",
        message: "API connection",
        time: "1 week"
      },
      {
        code: "200 OK",
        state: "success",
        message: "Database restore",
        time: "Mar 5"
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "System update",
        time: "May 15"
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "Server OS update",
        time: "Apr 3"
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "API rollback",
        time: "Jun 30"
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "Refund process",
        time: "Jul 10"
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "Withdrawal process",
        time: "Sep 10"
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "Mail tasks",
        time: "Dec 10"
      }
    ];

    return {
      data1,
      data2
    };
  }
});
