<template>
  <!--begin::Maintenance budget filter dropdown-->
  <a
      href="#"
      class="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
  >
            <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
              <inline-svg src="media/icons/duotone/Text/Filter.svg" />
            </span>
    Filter
  </a>
  <div
    class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
    data-kt-menu="true"
  >
    <!--begin::Header-->
    <div class="px-7 py-5">
      <div class="fs-5 text-dark fw-bolder">Filter Options</div>
    </div>
    <!--end::Header-->

    <!--begin::Menu separator-->
    <div class="separator border-gray-200"></div>
    <!--end::Menu separator-->

    <!--begin::Form-->
    <div class="px-7 py-5">
      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-bold">Employee:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
              class="form-select-solid"
              placeholder="Select option"
              v-model="data.type"
              @change="updateAssetsFilterType"
          >
            <el-option label="All" value="All">All</el-option>
            <el-option label="Eldar Musayev" value="Eldar Musayev">Eldar Musayev</el-option>
            <el-option label="Emil Rustamov" value="Emil Rustamov">Emil Rustamov</el-option>
            <el-option label="Oktay Heybatov" value="Oktay Heybatov">Oktay Heybatov</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-bold">Location:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
            class="form-select-solid"
            placeholder="Select option"
            v-model="data.status"
            @change="updateAssetsFilterStatus"
          >
            <el-option label="All" value="All">All</el-option>
            <el-option label="Home" value="Home">Home</el-option>
            <el-option label="On shift" value="On shift">On shift</el-option>
            <el-option label="CA" value="CA">CA</el-option>
            <el-option label="EA" value="EA">EA</el-option>
            <el-option label="WA" value="WA">WA</el-option>
            <el-option label="DWG" value="DWG">DWG</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-bold">Date:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
              class="form-select-solid"
              placeholder="Select option"
              v-model="data.owner"
              @change="updateAssetsFilterOwner"
          >
            <el-option label="All" value="All">All</el-option>
            <el-option label="This month" value="This month">This month</el-option>
            <el-option label="Next 3 months" value="Last 3 months">Next 3 months</el-option>
            <el-option label="Last 3 months" value="Last 3 months">Last 3 months</el-option>
            <el-option label="This year" value="This year">This year</el-option>
            <el-option label="Last year" value="Last year">Last year</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="d-flex justify-content-end">
        <button
          type="reset"
          @click="resetFilters"
          class="btn btn-sm btn-white btn-active-light-primary me-2"
          data-kt-menu-dismiss="true"
        >
          Reset
        </button>

        <button
          type="submit"
          @click="filterAssetsList"
          class="btn btn-sm btn-primary"
          data-kt-menu-dismiss="true"
        >
          Apply
        </button>
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Form-->
  </div>
  <!--end::Maintenance budget filter dropdown-->
</template>

<script lang="ts">
import { inject, defineComponent, ref, computed} from "vue";
import { Field } from "vee-validate";
import { useStore } from "vuex";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {emitter} from "@/main";

interface Filter {
  status: string;
  owner: string;
  type: string;
  keyword: string;
}

export default defineComponent({
  name: "workforce-filter-dropdown",
  components: {},
  methods: {
    resetFilters: function() {
      console.log("reset");
      this.data.status = "All";
      this.data.owner = "All";
      this.data.type = "All";
      this.filterAssetsList();
    },
    updateAssetsFilterStatus: function (selected) {
      this.data.status = selected;
      //this.filterAssetsList();
    },
    updateAssetsFilterOwner: function (selected) {
      this.data.owner = selected;
      //this.filterAssetsList();
    },
    updateAssetsFilterType: function (selected) {
      this.data.type = selected;
      //this.filterAssetsList();
    },
    updateAssetsFilterKeyword: function (selected) {
      this.data.keyword = selected.target.value;
      //this.filterAssetsList();
    },
    filterAssetsList: function() {
      //console.log("update triggered", this.data);
      //emitter.emit('filterAssetsList', this.data);
    }
  },
  setup() {
    const data = ref<Filter>({
      status: "All",
      owner: "All",
      type: "All",
      keyword: ""
    });

    return {
      data
    };
  }
});
</script>
