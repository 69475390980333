<template>
  <!--begin::Assets filter dropdown-->
  <a
      href="#"
      class="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
  >
            <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
              <inline-svg src="media/icons/duotone/Text/Filter.svg" />
            </span>
    Filter
  </a>
  <div
    class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
    data-kt-menu="true"
  >
    <!--begin::Header-->
    <div class="px-7 py-5">
      <div class="fs-5 text-dark fw-bolder">Filter Options</div>
    </div>
    <!--end::Header-->

    <!--begin::Menu separator-->
    <div class="separator border-gray-200"></div>
    <!--end::Menu separator-->

    <!--begin::Form-->
    <div class="px-7 py-5">
      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-bold">Type:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
              class="form-select-solid"
              placeholder="Select option"
              v-model="data.type"
              @change="updateAssetsFilterType"
          >
            <el-option label="All" value="All">All</el-option>
            <el-option-group label="Compressors">
              <el-option label="Offshore" value="Offshore">Offshore</el-option>
              <el-option label="Onshore" value="Onshore">Onshore</el-option>
              <el-option label="Breathing Air" value="Breathing Air">Breathing Air</el-option>
              <el-option label="All compressors" value="Compressor">All compressors</el-option>
            </el-option-group>
            <el-option-group label="Jetting">
              <el-option label="HP unit" value="HP unit">HP unit</el-option>
              <el-option label="UHP unit" value="UHP unit">UHP unit</el-option>
              <el-option label="Deck blaster" value="Deck blaster">Deck blaster</el-option>
              <el-option label="Jet" value="Jet">Jet</el-option>
              <el-option label="Centralizer" value="Centralizer">Centralizer</el-option>
              <el-option label="Ancillary" value="Ancillary">Ancillary</el-option>
              <el-option label="All jetting units" value="Jetting">All jetting units</el-option>
            </el-option-group>
            <el-option label="Hot Wash unit" value="Hot Wash unit">Hot Wash unit</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-bold">Status:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
            class="form-select-solid"
            placeholder="Select option"
            v-model="data.status"
            @change="updateAssetsFilterStatus"
          >
            <el-option label="All" value="All">All</el-option>
            <el-option label="In service" value="Online">In service</el-option>
            <el-option label="Out of service" value="Offline">Out of service</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-5 d-none">
        <!--begin::Label-->
        <label class="form-label fw-bold">Owner:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
              class="form-select-solid"
              placeholder="Select option"
              v-model="data.owner"
              @change="updateAssetsFilterOwner"
          >
            <el-option label="All" value="All">All</el-option>
            <!--<el-option label="Predsol" value="Predsol">Predsol</el-option>-->
            <el-option label="Socar Cape JV" value="Socar Cape JV">Socar Cape JV</el-option>
            <!--<el-option label="Equipment rental" value="Equipment rental">Equipment rental</el-option>
            <el-option label="Lifting" value="Lifting">Lifting</el-option>
            <el-option label="Pressure testing" value="Pressure testing">Pressure testing	</el-option>
            <el-option label="Calibration" value="Calibration">Calibration</el-option>-->
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-bold">Keyword:</label>
        <!--end::Label-->


        <!--begin::Input-->
        <div>
          <Field
              class="form-control form-control-lg"
              type="text"
              name="keyword"
              placeholder="Start typing..."
              autocomplete="off"
              v-model="data.keyword"
              @input="updateAssetsFilterKeyword"
          />
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="d-flex justify-content-end">
        <button
          type="reset"
          @click="resetFilters"
          class="btn btn-sm btn-white btn-active-light-primary me-2"
          data-kt-menu-dismiss="true"
        >
          Reset
        </button>

        <button
          type="submit"
          @click="filterAssetsList"
          class="btn btn-sm btn-primary"
          data-kt-menu-dismiss="true"
        >
          Apply
        </button>
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Form-->
  </div>
  <!--end::Assets filter dropdown-->
</template>

<script lang="ts">
import { inject, defineComponent, ref, computed} from "vue";
import { Field } from "vee-validate";
import { useStore } from "vuex";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {emitter} from "@/main";

interface Filter {
  status: string;
  owner: string;
  type: string;
  keyword: string;
}

export default defineComponent({
  name: "assets-filter-dropdown",
  components: {Field},
  methods: {
    resetFilters: function() {
      //console.log("reset");
      this.data.status = "All";
      this.data.owner = "All";
      this.data.type = "All";
      this.filterAssetsList();
    },
    updateAssetsFilterStatus: function (selected) {
      this.data.status = selected;
      //this.filterAssetsList();
    },
    updateAssetsFilterOwner: function (selected) {
      this.data.owner = selected;
      //this.filterAssetsList();
    },
    updateAssetsFilterType: function (selected) {
      this.data.type = selected;
      //this.filterAssetsList();
    },
    updateAssetsFilterKeyword: function (selected) {
      this.data.keyword = selected.target.value;
      //this.filterAssetsList();
    },
    filterAssetsList: function() {
      //console.log("update triggered", this.data);
      emitter.emit('filterAssetsList', this.data);
    }
  },
  setup() {
    const data = ref<Filter>({
      status: "All",
      owner: "All",
      type: "All",
      keyword: ""
    });

    return {
      data
    };
  }
});
</script>
