
import {inject, defineComponent, ref, computed} from "vue";
import { useStore } from "vuex";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {emitter} from "@/main";

interface Filter {
  status: string;
  type: string;
}

export default defineComponent({
  name: "work-orders-filter-dropdown",
  components: {},
  methods: {
    resetFilters: function() {
      console.log("reset");
      this.data.status = "All";
      this.data.type = "All";
      this.filterWorkOrdersList();
    },
    updateWorkOrdersFilterStatus: function (selected) {
      this.data.status = selected;
      this.filterWorkOrdersList();
    },
    updateWorkOrdersFilterType: function (selected) {
      this.data.type = selected;
      this.filterWorkOrdersList();
    },
    filterWorkOrdersList: function() {
      //console.log("update triggered", this.data);
      emitter.emit('filterWorkOrdersList', this.data);
    }
  },
  setup() {
    const data = ref<Filter>({
      status: "All",
      type: "All"
    });

    return {
      data
    };
  }
});
