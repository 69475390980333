<template>
  <!--begin::Projects filter dropdown-->
  <a
      href="#"
      class="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
  >
            <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
              <inline-svg src="media/icons/duotone/Text/Filter.svg" />
            </span>
    Filter
  </a>
  <div
    class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
    data-kt-menu="true"
  >
    <!--begin::Header-->
    <div class="px-7 py-5">
      <div class="fs-5 text-dark fw-bolder">Filter Options</div>
    </div>
    <!--end::Header-->

    <!--begin::Menu separator-->
    <div class="separator border-gray-200"></div>
    <!--end::Menu separator-->

    <!--begin::Form-->
    <div class="px-7 py-5">
      <!--begin::Input group-->
      <div class="mb-10">
        <!--begin::Label-->
        <label class="form-label fw-bold">Status:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
            class="form-select-solid"
            placeholder="Select option"
            v-model="data.status"
            @change="updateProjectsFilterStatus"
          >
            <el-option label="All" value="All">All</el-option>
            <el-option label="Awaiting PO award" value="Awaiting PO award">Awaiting PO award</el-option>
            <el-option label="Cancelled" value="Cancelled">Cancelled</el-option>
            <el-option label="In progress" value="In progress">In progress</el-option>
            <el-option label="Invoiced" value="Invoiced">Invoiced</el-option>
            <el-option label="Job completed" value="Job completed">Job completed</el-option>
            <el-option label="Paid" value="Paid">Paid</el-option>
            <el-option label="Proposal needs to be submitted" value="Proposal needs to be submitted">Proposal needs to be submitted</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-10">
        <!--begin::Label-->
        <label class="form-label fw-bold">Customer:</label>
        <!--end::Label-->


        <!--begin::Input-->
        <div>
          <el-select
              class="form-select-solid"
              placeholder="Select option"
              v-model="data.customer"
              @change="updateProjectsFilterCustomer"
          >
            <el-option label="All" value="All">All</el-option>
            <el-option label="Baker Hughes" value="Baker Hughes">Baker Hughes</el-option>
            <el-option label="Socar CAPE JV" value="Socar CAPE JV">Socar CAPE JV</el-option>
            <el-option label="TechnipFMC" value="TechnipFMC">TechnipFMC</el-option>
            <el-option label="Halliburton" value="Halliburton">Halliburton</el-option>
            <!--<el-option label="Equipment rental" value="Equipment rental">Equipment rental</el-option>
            <el-option label="Lifting" value="Lifting">Lifting</el-option>
            <el-option label="Pressure testing" value="Pressure testing">Pressure testing	</el-option>
            <el-option label="Calibration" value="Calibration">Calibration</el-option>-->
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="d-flex justify-content-end">
        <button
          type="reset"
          @click="resetFilters"
          class="btn btn-sm btn-white btn-active-light-primary me-2"
          data-kt-menu-dismiss="true"
        >
          Reset
        </button>

        <button
          type="submit"
          class="btn btn-sm btn-primary"
          data-kt-menu-dismiss="true"
        >
          Apply
        </button>
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Form-->
  </div>
  <!--end::Projects filter dropdown-->
</template>

<script lang="ts">
import {inject, defineComponent, ref, computed} from "vue";
import { useStore } from "vuex";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {emitter} from "@/main";

interface Filter {
  status: string;
  customer: string;
}

export default defineComponent({
  name: "projects-filter-dropdown",
  components: {},
  methods: {
    resetFilters: function() {
      //console.log("reset");
      this.data.status = "All";
      this.data.customer = "All";
      this.filterProjectsList();
    },
    updateProjectsFilterStatus: function (selected) {
      this.data.status = selected;
      this.filterProjectsList();
    },
    updateProjectsFilterCustomer: function (selected) {
      this.data.customer = selected;
      this.filterProjectsList();
    },
    filterProjectsList: function() {
      //console.log("update triggered", this.data);
      emitter.emit('filterProjectsList', this.data);
    }
  },
  setup() {
    const data = ref<Filter>({
      status: "All",
      customer: "All"
    });

    return {
      data
    };
  }
});
</script>
