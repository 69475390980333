
import { inject, defineComponent, ref, computed} from "vue";
import { Field } from "vee-validate";
import { useStore } from "vuex";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {emitter} from "@/main";

interface Filter {
  status: string;
  owner: string;
  type: string;
  keyword: string;
}

export default defineComponent({
  name: "assets-filter-dropdown",
  components: {Field},
  methods: {
    resetFilters: function() {
      //console.log("reset");
      this.data.status = "All";
      this.data.owner = "All";
      this.data.type = "All";
      this.filterAssetsList();
    },
    updateAssetsFilterStatus: function (selected) {
      this.data.status = selected;
      //this.filterAssetsList();
    },
    updateAssetsFilterOwner: function (selected) {
      this.data.owner = selected;
      //this.filterAssetsList();
    },
    updateAssetsFilterType: function (selected) {
      this.data.type = selected;
      //this.filterAssetsList();
    },
    updateAssetsFilterKeyword: function (selected) {
      this.data.keyword = selected.target.value;
      //this.filterAssetsList();
    },
    filterAssetsList: function() {
      //console.log("update triggered", this.data);
      emitter.emit('filterAssetsList', this.data);
    }
  },
  setup() {
    const data = ref<Filter>({
      status: "All",
      owner: "All",
      type: "All",
      keyword: ""
    });

    return {
      data
    };
  }
});
