const DocMenuConfig: object = [
  {
    pages: [
     /* {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotone/Shopping/Chart-bar1.svg",
        fontIcon: "bi-app-indicator"
      },*/
      {
        heading: "Dashboard",
        route: "/dashboard2",
        svgIcon: "media/icons/duotone/Shopping/Chart-pie.svg",
        fontIcon: "bi-app-indicator"
      },
      {
        heading: "Asset management",
        route: "/assets-categories",
        svgIcon: "media/icons/duotone/Interface/Grid.svg",
        fontIcon: "bi-text-left",
        sub: [/*
          {
            heading: "Asset inventory",
            route: "/assets-list",
          },
          {
            heading: "Availability schedule",
            route: "/assets-schedule",
          },
          {
            heading: "Insurance",
            route: "#"
          }*/
        ]
      },
      /*{
        sectionTitle: "Project management",
        route: "#",
        svgIcon: "media/icons/duotone/Text/Align-left.svg",
        fontIcon: "bi-text-left",
        sub: [
          {
            heading: "Planning and budgeting",
            route: "#"
          },
          {
            heading: "Project definition",
            route: "#"
          },
          {
            heading: "Project planning and tracking",
            route: "/projects-list",
          },
          {
            heading: "Billing",
            route: "#",
          },
          {
            heading: "Project closure",
            route: "#"
          }
        ]
      },*/
      {
        sectionTitle: "Document database",
        route: "/document-db",
        svgIcon: "media/icons/duotone/Files/File.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Manuals",
            route: "/document-db/manuals"
          },
          {
            heading: "Maintenance plan",
            route: "/document-db/maintenance-plan"
          },
          {
            heading: "Procedures",
            route: "/document-db/procedures"
          },
          {
            heading: "Checklists",
            route: "/document-db/checklists"
          },
          {
            heading: "Certificates",
            route: "/document-db/certificates"
          },
          {
            heading: "Maintenance reports",
            route: "/document-db/maintenance-records"
          },
          {
            heading: "Fault reports",
            route: "/document-db/fault-reports"
          }/*,
          {
            heading: "ddbRiskAssesment",
            route: "#"//"/document-db/risk-assesment"
          },
          {
            heading: "ddbGuidelines",
            route: "#"//"/document-db/guidelines"
          }*/
        ]
      },
      /*{
        sectionTitle: "Inventory and procurement",
        route: "#",
        svgIcon: "media/icons/duotone/Shopping/Box3.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Inventory analysis and planning",
            route: "#"
          },
          {
            heading: "Warehouse management",
            route: "#"
          },
          {
            heading: "Vendor management",
            route: "#"
          },
          {
            heading: "Repair & Exchange",
            route: "#"
          },
          {
            heading: "Physical inventory & cycle counting",
            route: "#"
          },
          {
            heading: "Sourcing & purchasing",
            route: "#"
          },
          {
            heading: "Inbound logistics",
            route: "#"
          },
          {
            heading: "Subcontracting",
            route: "#"
          },
          {
            heading: "Outbound logistics",
            route: "#"
          }
        ]
      },*/
      {
        heading: "Maintenance schedule",
        route: "/maintenance/calendar",
        svgIcon: "media/icons/duotone/Tools/Tools.svg",
        fontIcon: "bi-text-left"
      },
      /*{
        sectionTitle: "Work management",
        route: "#",
        svgIcon: "media/icons/duotone/General/Thunder-move.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Work orders",
            route: "/work-orders"
          },
          {
            heading: "Calendar",
            route: "/work-orders/calendar"
          },/*
          {
            heading: "Scheduler",
            route: "/work-orders/scheduler"
          },
          {
            heading: "Schedule",
            route: "/work-orders/gantt-chart"
          }
        ]
      },
      {
        sectionTitle: "Rental and lease management",
        route: "#",
        svgIcon: "media/icons/duotone/Interface/Sign-Out.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Lease inquiry",
            route: "#"
          },
          {
            heading: "Site inspection",
            route: "#"
          },
          {
            heading: "Quotation",
            route: "#"
          },
          {
            heading: "Lease order management",
            route: "#"
          },
          {
            heading: "Reservations",
            route: "#"
          },
          {
            heading: "Distributions",
            route: "#"
          },
          {
            heading: "Operator certification",
            route: "#"
          },
          {
            heading: "Rental rate table",
            route: "#"
          },
          {
            heading: "Operator assignment",
            route: "#"
          },
          {
            heading: "Lead management",
            route: "#"
          },
          {
            heading: "Time sheet",
            route: "#"
          },
          {
            heading: "Availability management",
            route: "#"
          },
          {
            heading: "Customer portal",
            route: "#"
          },
          {
            heading: "Billing",
            route: "#"
          }
        ]
      },
      {
        sectionTitle: "Finance and accounting",
        route: "#",
        svgIcon: "media/icons/duotone/Shopping/Dollar.svg",
        fontIcon: "bi-person",
        sub: [
          {
            sectionTitle: "Cashflow management",
            route: "#",
            sub: [
              {
                heading: "Receivables management",
                route: "#"
              },
              {
                heading: "Payables management",
                route: "#"
              },
              {
                heading: "Planning and budgeting",
                route: "#"
              },
              {
                heading: "Online banking (Unibank integration)",
                route: "#"
              }
            ]
          },
          {
            heading: "Profit & loss management",
            route: "#"
          },
          {
            sectionTitle: "Balance management",
            route: "#",
            sub: [
              {
                heading: "Fixed asset capitalization",
                route: "#"
              },
              {
                heading: "Fixed asset depreciation",
                route: "#"
              },
              {
                heading: "Fixed asset revaluation",
                route: "#"
              },
            ]
          },
          {
            heading: "Consolidation and reporting",
            route: "#"
          }
        ]
      },
      {
        sectionTitle: "Human capital management",
        route: "#",
        svgIcon: "media/icons/duotone/Communication/Group.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Workforce management",
            route: "/hcm/workforce-management"
          },
          {
            heading: "Recruitment",
            route: "#"
          },
          {
            heading: "Employee development",
            route: "#"
          },
          {
            heading: "Payroll & benefits",
            route: "#"
          },
          {
            heading: "Resource planning",
            route: "#"
          }
        ]
      }*/
    ]
  }/*,
  {
    heading: "For development",
    route: "#",
    pages: [
      {
        sectionTitle: "UI element pages",
        route: "/pages",
        svgIcon: "media/icons/duotone/Code/Compiling.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            sectionTitle: "profile",
            route: "/profile",
            sub: [
              {
                heading: "profileOverview",
                route: "/crafted/pages/profile/overview"
              },
              {
                heading: "projects",
                route: "/crafted/pages/profile/projects"
              },
              {
                heading: "campaigns",
                route: "/crafted/pages/profile/campaigns"
              },
              {
                heading: "documents",
                route: "/crafted/pages/profile/documents"
              },
              {
                heading: "connections",
                route: "/crafted/pages/profile/connections"
              },
              {
                heading: "activity",
                route: "/crafted/pages/profile/activity"
              }
            ]
          },
          {
            sectionTitle: "wizards",
            route: "/wizard",
            sub: [
              {
                heading: "horizontal",
                route: "/crafted/pages/wizards/horizontal"
              },
              {
                heading: "vertical",
                route: "/crafted/pages/wizards/vertical"
              }
            ]
          },
          {
            sectionTitle: "account",
            route: "/crafted/account",
            sub: [
              {
                heading: "accountOverview",
                route: "/crafted/account/overview"
              },
              {
                heading: "settings",
                route: "/crafted/account/settings"
              }
            ]
          },
          {
            sectionTitle: "authentication",
            svgIcon: "media/icons/duotone/Interface/Lock.svg",
            fontIcon: "bi-sticky",
            sub: [
              {
                sectionTitle: "basicFlow",
                sub: [
                  {
                    heading: "signIn",
                    route: "/sign-in"
                  },
                  {
                    heading: "signUp",
                    route: "/sign-up"
                  },
                  {
                    heading: "passwordReset",
                    route: "/password-reset"
                  }
                ]
              },
              {
                heading: "error404",
                route: "/404"
              },
              {
                heading: "error500",
                route: "/500"
              }
            ]
          },
          {
            sectionTitle: "modals",
            route: "/modals",
            svgIcon: "media/icons/duotone/Design/Substract.svg",
            fontIcon: "bi-shield-check",
            sub: [
              {
                sectionTitle: "general",
                route: "/general",
                sub: [
                  {
                    heading: "inviteFriends",
                    route: "/crafted/modals/general/invite-friends"
                  },
                  {
                    heading: "viewUsers",
                    route: "/crafted/modals/general/view-user"
                  },
                  {
                    heading: "upgradePlan",
                    route: "/crafted/modals/general/upgrade-plan"
                  },
                  {
                    heading: "shareAndEarn",
                    route: "/crafted/modals/general/share-and-earn"
                  }
                ]
              },
              {
                sectionTitle: "forms",
                route: "/forms",
                sub: [
                  {
                    heading: "newTarget",
                    route: "/crafted/modals/forms/new-target"
                  },
                  {
                    heading: "newCard",
                    route: "/crafted/modals/forms/new-card"
                  },
                  {
                    heading: "newAddress",
                    route: "/crafted/modals/forms/new-address"
                  },
                  {
                    heading: "createAPIKey",
                    route: "/crafted/modals/forms/create-api-key"
                  }
                ]
              },
              {
                sectionTitle: "wizards",
                route: "/wizards",
                sub: [
                  {
                    heading: "twoFactorAuth",
                    route: "/crafted/modals/wizards/two-factor-auth"
                  },
                  {
                    heading: "createApp",
                    route: "/crafted/modals/wizards/create-app"
                  },
                  {
                    heading: "createAccount",
                    route: "/crafted/modals/wizards/create-account"
                  }
                ]
              }
            ]
          },
          {
            sectionTitle: "widgets",
            route: "/widgets",
            svgIcon: "media/icons/duotone/Layout/Layout-4-blocks.svg",
            fontIcon: "bi-layers",
            sub: [
              {
                heading: "widgetsLists",
                route: "/crafted/widgets/lists"
              },
              {
                heading: "widgetsStatistics",
                route: "/crafted/widgets/statistics"
              },
              {
                heading: "widgetsCharts",
                route: "/crafted/widgets/charts"
              },
              {
                heading: "widgetsMixed",
                route: "/crafted/widgets/mixed"
              },
              {
                heading: "widgetsTables",
                route: "/crafted/widgets/tables"
              },
              {
                heading: "widgetsFeeds",
                route: "/crafted/widgets/feeds"
              }
            ]
          },
          {
            sectionTitle: "chat",
            route: "/chat",
            svgIcon: "media/icons/duotone/Communication/Group-chat.svg",
            fontIcon: "bi-chat-left",
            sub: [
              {
                heading: "privateChat",
                route: "/apps/chat/private-chat"
              },
              {
                heading: "groupChat",
                route: "/apps/chat/group-chat"
              },
              {
                heading: "drawerChat",
                route: "/apps/chat/drawer-chat"
              }
            ]
          }
        ]
      }
    ]
  }*/
];

export default DocMenuConfig;
