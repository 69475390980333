
import {inject, defineComponent, ref, computed} from "vue";
import { useStore } from "vuex";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {emitter} from "@/main";

interface Filter {
  status: string;
  customer: string;
}

export default defineComponent({
  name: "projects-filter-dropdown",
  components: {},
  methods: {
    resetFilters: function() {
      //console.log("reset");
      this.data.status = "All";
      this.data.customer = "All";
      this.filterProjectsList();
    },
    updateProjectsFilterStatus: function (selected) {
      this.data.status = selected;
      this.filterProjectsList();
    },
    updateProjectsFilterCustomer: function (selected) {
      this.data.customer = selected;
      this.filterProjectsList();
    },
    filterProjectsList: function() {
      //console.log("update triggered", this.data);
      emitter.emit('filterProjectsList', this.data);
    }
  },
  setup() {
    const data = ref<Filter>({
      status: "All",
      customer: "All"
    });

    return {
      data
    };
  }
});
